
import { Component, Vue } from 'vue-property-decorator'
import echarts from 'echarts'
import { twoDecimalNoZero } from '@/utils/validate'
import { ElForm } from 'element-ui/types/form'

@Component
export default class IrrigationConsume extends Vue {
    icon1 = require('@/assets/img/irrigation/icon_content_rengongjiaoshui.png')
    icon2 = require('@/assets/img/irrigation/icon_content_zhinengpenguan.png')

    private loading=false
    private echartNameList=['工人', '水车', '水费', '电费', '间接费', '合计']
    private maintainNameList=[
      {
        name: '工人成本',
        value: 'workerCost',
        valueRate: 'workerCostRate'
      },
      {
        name: '水车成本',
        value: 'waterwheelCost',
        valueRate: 'waterwheelCostRate'
      },
      {
        name: '水费成本',
        value: 'waterCost',
        valueRate: 'waterCostRate'
      },
      {
        name: '电费成本',
        value: 'electricityCost',
        valueRate: 'electricityCostRate'
      },
      {
        name: '间接费成本',
        value: 'indirectCost',
        valueRate: 'indirectCostRate'
      },
      {
        name: '合计成本',
        value: 'allCost',
        valueRate: 'allCostRate'
      }
    ]

    private yearSavingCost= '' // 节约成本
    private yearSavingCostRate= '' // 节约率
    private searchInfo= {
      area: null,
      waterType: null
    }

    private rules= {
      area: [
        { required: true, message: '请输入浇水面积', trigger: ['change'], whitespace: true },
        { validator: twoDecimalNoZero, trigger: ['blur', 'change'] }
      ],
      waterType: [
        { required: true, message: '请选择用水类型', trigger: 'change' }
      ]
    }

    private tableInfo= {
      artificialCost: {},
      irrigationCost: {}
    }

    private contrastBarEchart: any= null
    private contrastBarEchart2: any= null
    private efficacyEchart: any= null
    created () {
      window.addEventListener('resize', this.eChartResize)
      this.echartData()
    }

    // 图表数据请求
    echartData () {
      this.$axios.get(this.$apis.irrigationConsume.effectCompared).then((res) => {
        this.yearSavingCost = res.yearSavingCost ? res.yearSavingCost : 0
        this.yearSavingCostRate = res.yearSavingCostRate ? res.yearSavingCostRate : '0%'
        this.formatInfo(
          res.artificialCost ? res.artificialCost : '',
          res.irrigationCost ? res.irrigationCost : ''
        )
      })
    }

    // 图表数据处理
    formatInfo (artificialCost: any, irrigationCost: any) {
      const leftData = artificialCost
        ? [
          // 人工
          artificialCost.workerCost,
          artificialCost.waterwheelCost,
          artificialCost.waterCost,
          artificialCost.electricityCost,
          artificialCost.indirectCost,
          artificialCost.allCost
        ]
        : []
      const rightData = irrigationCost
        ? [
          // 智慧
          irrigationCost.workerCost,
          irrigationCost.waterwheelCost,
          irrigationCost.waterCost,
          irrigationCost.electricityCost,
          irrigationCost.indirectCost,
          irrigationCost.allCost
        ]
        : []
      this.drawContrast(leftData, rightData)
    }

    // 测算查询
    onSubmit () {
      (this.$refs.searchInfo as ElForm).validate((valid) => {
        if (valid) {
          this.loading = true
          this.$axios.get(this.$apis.irrigationConsume.effectCalculation, this.searchInfo)
            .then((res) => {
              this.tableInfo = res || {}
              this.loading = false
            })
            .catch((error) => {
              this.loading = false
              this.$message.error(error.message)
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }

    // 柱状图
    drawContrast (leftData: any, rightData: any) {
      this.$nextTick(() => {
        this.contrastBarEchart = echarts.init((this.$refs.contrastBar as any))
        this.contrastBarEchart2 = echarts.init((this.$refs.contrastBar2 as any))
        const legend = ['人工浇水', '智慧喷灌']
        const colors = [
          {
            borderColor: 'rgb(255,147,40)',
            start: 'rgba(255,147,40,0.8)',
            end: 'rgba(255,147,40,0.9)',
            borEnd: {
              borderColor: 'rgb(255,112,40)',
              start: 'rgba(255,112,40,0.8)',
              end: 'rgba(255,112,40,0.9)'
            }
          },
          {
            borderColor: 'rgb(38,226,255)',
            start: 'rgba(38,226,255,0.8)',
            end: 'rgba(38,226,255,0.9)',
            borEnd: {
              borderColor: 'rgb(30,162,255)',
              start: 'rgba(30,162,255,0.8)',
              end: 'rgba(30,162,255,0.9)'
            }
          }
        ]
        // 根据最大值设置图表最大值
        const max = leftData[leftData.length - 1]
        let borderData = []
        const scale = 1
        borderData = this.echartNameList.map(() => {
          return scale
        })
        const option = {
          baseOption: {
            backgroundColor: '#fff',
            timeline: {
              show: false,
              top: 0,
              data: []
            },
            legend: {
              top: 30,
              right: 0,
              itemWidth: 21,
              itemHeight: 18,
              icon: `image://${this.icon1}`,
              textStyle: {
                fontWeight: 'bold',
                fontSize: 18
              },
              data: legend
            },
            grid: [
              {
                right: 0,
                left: 160,
                show: false,
                top: 60,
                bottom: 0,
                containLabel: true
              }
            ],
            xAxis: [
              {
                type: 'value',
                inverse: true,
                max: max,
                axisTick: {
                  show: false
                },
                axisLine: {
                  show: false
                },
                axisLabel: {
                  show: false
                },
                splitLine: {
                  show: false
                }
              }
            ],
            yAxis: [
              {
                type: 'category',
                inverse: true,
                position: 'right',
                axisLine: {
                  show: false
                },

                axisTick: {
                  show: false
                },
                axisLabel: {
                  show: false
                },
                data: this.echartNameList
              }
            ],
            series: [
              {
                name: '人工浇水',
                type: 'bar',
                barWidth: 24,
                stack: '1',
                itemStyle: {
                  normal: {
                    color: function (params: {dataIndex: number}) {
                      const color = params.dataIndex === 5 ? colors[0].borEnd : colors[0]
                      return new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                        {
                          offset: 0,
                          color: color.start
                        },
                        {
                          offset: 1,
                          color: color.end
                        }
                      ])
                    }
                  }
                },
                label: {
                  normal: {
                    show: true,
                    position: 'left',
                    // color: '#fff',
                    fontSize: 16,
                    offset: [-30, 0],
                    formatter: '{c}/年'
                  }
                },
                data: leftData,
                animationEasing: 'elasticOut'
              },
              {
                name: '人工浇水',
                type: 'bar',
                barWidth: 24,
                stack: '1',
                barMinHeight: 10,
                itemStyle: {
                  normal: {
                    color: function (params: {dataIndex: number}) {
                      return params.dataIndex === 5
                        ? colors[0].borEnd.borderColor
                        : colors[0].borderColor
                    }
                  }
                },
                data: borderData
              }
            ]
          }
        }
        const option2 = {
          baseOption: {
            backgroundColor: '#fff',
            timeline: {
              show: false,
              top: 0,
              data: []
            },
            legend: {
              top: 30,
              left: 0,
              itemWidth: 20,
              itemHeight: 18,
              icon: `image://${this.icon2}`,
              pageButtonGap: 10,
              textStyle: {
                fontWeight: 'bold',
                fontSize: 18
              },
              data: legend
            },
            grid: [
              {
                show: false,
                left: 0,
                right: 160,
                top: 60,
                bottom: 0,
                containLabel: true
              }
            ],
            xAxis: [
              {
                type: 'value',
                inverse: false,
                max: max,
                axisLine: {
                  show: false
                },
                axisTick: {
                  show: false
                },
                axisLabel: {
                  show: false
                },
                splitLine: {
                  show: false
                }
              }
            ],
            yAxis: [
              {
                type: 'category',
                inverse: true,
                position: 'left',
                axisLine: {
                  show: false
                },

                axisTick: {
                  show: false
                },
                axisLabel: {
                  show: false
                },
                data: this.echartNameList
              }
            ],
            series: [
              {
                name: '智慧喷灌',
                type: 'bar',
                barWidth: 24,
                stack: '1',
                itemStyle: {
                  normal: {
                    color: function (params: {dataIndex: number}) {
                      const color = params.dataIndex === 5 ? colors[1].borEnd : colors[1]
                      return new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                        {
                          offset: 0,
                          color: color.start
                        },
                        {
                          offset: 1,
                          color: color.end
                        }
                      ])
                    }
                  }
                },
                label: {
                  normal: {
                    show: true,
                    position: 'right',
                    // color: '#fff',
                    fontSize: 16,
                    offset: [30, 0],
                    formatter: '{c}/年'
                  }
                },
                data: rightData,
                animationEasing: 'elasticOut'
              },
              {
                name: '智慧喷灌',
                type: 'bar',
                barWidth: 24,
                stack: '1',
                barMinHeight: 10,
                itemStyle: {
                  normal: {
                    color: function (params: {dataIndex: number}) {
                      return params.dataIndex === 5
                        ? colors[1].borEnd.borderColor
                        : colors[1].borderColor
                    }
                  }
                },
                data: borderData
              }
            ]
          }
        }
        this.contrastBarEchart.setOption(option)
        this.contrastBarEchart2.setOption(option2)
      })
    }

    // 图表自适应
    eChartResize () {
      this.contrastBarEchart.resize()
      this.contrastBarEchart2.resize()
      if (this.efficacyEchart) {
        this.efficacyEchart.resize()
      }
    }
}
